import Axios, { AxiosRequestConfig } from "axios";

export interface IHttpRepository {
  postAsync<TRequest, TResponse>(
    endPoint: string,
    postObject: TRequest,
    token?: string
  ): Promise<TResponse>;
}

export const HttpRepository = (url: string): IHttpRepository => {
  const getAxiosConfiguration = (url: string): AxiosRequestConfig => {
    return {
      timeout: 120000,
      baseURL: url,
    };
  };

  const axiosInstance = Axios.create(getAxiosConfiguration(url));

  const configureInterceptors = (
    token?: string,
    headers?: Array<{ key: string; value: string }>
  ) => {
    if (token || headers) {
      axiosInstance.interceptors.request.use((request) =>
        addReaders(request, token, headers)
      );
    }
  };

  const addReaders = (
    request: AxiosRequestConfig,
    token?: string,
    headers?: Array<{ key: string; value: string }>
  ): AxiosRequestConfig => {
    headers?.forEach((header) => {
      request.headers[header.key] = header.value;
    });
    if (token) {
      addAuthorizationBearer(request, token);
    }
    return request;
  };

  const addAuthorizationBearer = (
    request: AxiosRequestConfig,
    token?: string
  ): AxiosRequestConfig => {
    request.headers["Authorization"] = "Bearer " + token;
    request.headers["contentType"] = "application/json; charset=utf-8";
    return request;
  };

  const postAsync = async <TRequest extends unknown, TResponse extends unknown>(
    endPoint: string,
    postObject: TRequest,
    token?: string
  ): Promise<TResponse> => {
    configureInterceptors(token);
    const result = await axiosInstance.post<TResponse>(endPoint, postObject);
    console.log(result);
    return result.data;
  };

  return { postAsync };
};

export interface Enviroment {
  apiUrl: string;
  appUrl: string;
}
const enviroment = {
  apiUrl: process.env.REACT_APP_API_URL,
  appUrl: process.env.REACT_APP_URL_APP,
} as Enviroment;
export default enviroment;

//https://create-react-app.dev/docs/adding-custom-environment-variables/
